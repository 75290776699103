export interface GeonetworkMappedMetadata {
  title: string;
  description?: string;
  processing?: string;

  license: string;

  timespan?: {
    start_date: string;
    end_date: string;
  }
}

export interface DatasetMetainfo extends GeonetworkMappedMetadata {
  last_updated: Date;
  last_retrieved: Date;
  first_retrieved: Date;
}

export enum ExternalReferenceType {
  ZENODO = "ZENODO",
  GEONETWORK = "GEONETWORK"
}

export interface ExternalReference {
  platform: ExternalReferenceType;
  identifier: string;
  // url: string;
  label?: string; // Optional label for multi-record sets
}
