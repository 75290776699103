import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { AuthService } from '../core/auth/auth.service';

import { GeonetworkToolService } from './header.service';
import { GEONETWORK_URL } from '../constant';
import { GeoNetworkTool } from '@models/general/GeoNetworkTool';

export const COVARIATE_TAB = "covariates";
export const DISEASES_TAB = "diseases";

const DefaultCategories: GeonetworkToolCategories[] = [
  {
    label: 'Text Mining',
    tools: []
  },
  {
    label: 'Normalisation',
    tools: []
  }, {
    label: 'Risk Mapping',
    tools: []
  },
  {
    label: 'Other Tools',
    tools: []
  }
];

interface Tool {
  label: string;
  route: string;
  external?: boolean;
  params?: {}
}

interface GeonetworkToolCategories {
  label: 'Text Mining' | 'Normalisation' | 'Risk Mapping' | 'Other Tools';
  tools: GeoNetworkTool[];
}

@Component({
  selector: 'mood-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
  constructor(private _router: Router, private _authService: AuthService, private _toolService: GeonetworkToolService) { }

  private readonly adminTool: Tool = {
    label: "Dataset admin",
    route: "admin"
  };

  tools: Tool[] = [
    {
      label: "Covariates",
      route: "core",
      params: {
        tab: COVARIATE_TAB
      }
    },
    {
      label: "Diseases",
      route: "core",
      params: {
        tab: DISEASES_TAB
      }
    },
    {
      label: "GeoNetwork",
      route: "https://mood-geonetwork.avia-gis.com/geonetwork/srv/eng/catalog.search",
      external: true
    },
    // {
    //   label: "Epid Data Explorer",
    //   route: "tool/epid-data-explorer"
    // },
    {
      label: "PADI-web",
      route: "https://padi-web.cirad.fr/en/",
      external: true
    }
  ];

  codeEntries: GeonetworkToolCategories[] = structuredClone(DefaultCategories);
  toolEntries: GeonetworkToolCategories[] = structuredClone(DefaultCategories);

  ngOnInit(): void {
    this.loadAndMapTools();

    this._authService._loggedIn$.subscribe(loggedIn => {
      if (loggedIn && this._authService.userHasAdminRole) {
        this.tools.push(this.adminTool);
      } else if (this.tools.includes(this.adminTool)) {
        this.tools = this.tools.filter(tool => tool !== this.adminTool);
      }
    });
  }

  async loadAndMapTools() {
    const tools = await this._toolService.loadGeonetworkTools();

    tools.map(tool => {
      switch (tool.type) {
        case 'service':
          this._checkToolToAdd(this.toolEntries, tool);
          break;

        case 'software':
          this._checkToolToAdd(this.codeEntries, tool);
          break;
      }
    });

    this.toolEntries = this.toolEntries.filter(category => category.tools.length !== 0);
    this.toolEntries.forEach(category => {
      category.tools.sort((toolA, toolB) => toolA.title.localeCompare(toolB.title));
    });

    this.codeEntries = this.codeEntries.filter(category => category.tools.length !== 0);
    this.codeEntries.forEach(category => {
      category.tools.sort((toolA, toolB) => toolA.title.localeCompare(toolB.title));
    });
  }

  private _checkToolToAdd(group: GeonetworkToolCategories[], tool: GeoNetworkTool) {
    let isMapped = false;
    if (tool.subject.includes("text-mining")) {
      group.find(category => category.label === 'Text Mining')?.tools.push(tool);
      isMapped = true;
    }

    if (tool.subject.includes("risk mapping") || tool.subject.includes("simulation")) {
      group.find(category => category.label === "Risk Mapping")?.tools.push(tool);
      isMapped = true;
    }

    if (tool.subject.includes("data normalisation")) {
      group.find(category => category.label === "Normalisation")?.tools.push(tool);
      isMapped = true;
    }

    if (!isMapped) {
      group.find(category => category.label === "Other Tools")?.tools.push(tool);
    }
  }

  switchToGeoTool(tool: GeoNetworkTool) {
    window.open(`${GEONETWORK_URL}${tool.identifier}`, '_blank');
  }

  switchToTool(tool: Tool) {
    if (tool.external) {
      window.open(tool.route, '_blank');
    } else {
      this._router.navigate([tool.route], { queryParams: tool.params });
    }
  }
}
