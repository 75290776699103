export enum ApiErrors {
  TooManyDownloads = "TOO_MANY_DOWNLOADS",
}

export enum AuthErrors {
  UserNotFound = "USER_NOT_FOUND",
  UserAlreadyExists = "USER_ALREADY_EXISTS",
  IncorrectPassword = "INCORRECT_PASSWORD",
  AccountNotActive = "ACCOUNT_NOT_ACTIVE",
  VerificationTokenMissing = "VERIFICATION_TOKEN_MISSING",
  IncorrectVerificationToken = "INCORRECT_VERIFICATION_TOKEN",
}

export enum PasswordResetErrors {
  PasswordResetEntryNotFound = "PASSWORD_RESET_ENTRY_NOT_FOUND",
  UserNotFound = "USER_NOT_FOUND"
}
