import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, catchError, filter, switchMap, take, tap, throwError } from "rxjs";
import { AuthService } from "./auth.service";
import { InterceptorErrorService } from "./error.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing$ = new BehaviorSubject<boolean>(false);

  constructor(private _authService: AuthService, private _errorHandler: InterceptorErrorService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = req;
    const token = this._authService.getToken();

    if (token) {
      authReq = this.addTokenHeader(req, token);
    }

    return next.handle(authReq).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && !authReq.url.includes('auth/login')  && !authReq.url.includes('auth/refreshtoken') && error.status === 401) {
          return this.handle401Error(authReq, next);
        }

        return throwError(() => error);
      })
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({ headers: request.headers.set("Authorization", `Bearer ${token}`) });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing$.getValue()) {
      this.isRefreshing$.next(true);

      const refreshToken = this._authService.getRefreshToken();

      if (refreshToken) {
        return this._authService.refreshToken(refreshToken)
          .pipe(
            tap(() => this.isRefreshing$.next(false)),
            switchMap((tokens) => {
              return next.handle(this.addTokenHeader(request, tokens.token))
            }),
            catchError((error) => {
              return this.handleRefreshFailure(error);
            })
          );
      } else {
        return this.handleRefreshFailure(new HttpErrorResponse({
          status: 401,
          error: "No refresh token found"
        }));
      }
    }

    return this.isRefreshing$
      .pipe(
        filter((is) => !is),
        take(1),
        switchMap(() => {
          const accessToken = this._authService.getToken();
          return next.handle(this.addTokenHeader(request, accessToken!));
        })
      );
  }

  private handleRefreshFailure(error: any) {
    if (error instanceof HttpErrorResponse && error.status === 401) {
      this._errorHandler.notifySessionExpired();
      this._authService.logout();
    }

    this.isRefreshing$.next(false);
    return throwError(() => error);
  }
}
