import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../auth.service';

import { AuthErrors } from '@models/helpers/errors'
import { RegisterDialogComponent } from '../register/register-dialog.component';

@Component({
  selector: 'mood-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginDialogComponent {
  loginForm = new FormGroup({
    email: new FormControl("", [Validators.required, Validators.email]),
    password: new FormControl("", [Validators.required])
  });

  constructor(public dialogRef: MatDialogRef<any>, private _authService: AuthService, private _dialog: MatDialog) { }

  submitLogin() {
    this._authService.login(this.emailControl?.value!, this.passwordControl?.value!)
      .subscribe({
        next: result => {
          this.dialogRef.close();
        },
        error: (error: HttpErrorResponse) => {
          if (error.error) {
            let errorObject = error.error as { message: string, code: AuthErrors };

            switch (errorObject.code) {
              case AuthErrors.UserNotFound:
              case AuthErrors.AccountNotActive:
                this.emailControl?.setErrors({ error: errorObject.message });
                break;

              case AuthErrors.IncorrectPassword:
                this.passwordControl?.setErrors({ error: errorObject.message });
                break;
            }

            this.loginForm.markAllAsTouched();
          }
        }
      });
  }

  openRegister() {
    this.dialogRef.close();

    this._dialog.open(RegisterDialogComponent, {
        height: '450px',
        width: '400px',
        panelClass: 'register-dialog'
    });
  }

  public getErrorMessage(control: AbstractControl | null) {
    if (control!.getError("error")) {
      return control!.getError("error");
    } else {
      return "This field is required";
    }
  }

  public get emailControl() {
    return this.loginForm.get('email');
  }

  public get passwordControl() {
    return this.loginForm.get('password');
  }
}
