import { Injectable } from "@angular/core";
import { HeaderModule } from "./header.module";
import { HttpClient } from "@angular/common/http";
import { GeoNetworkTool } from "@models/general/GeoNetworkTool";
import { lastValueFrom } from "rxjs";

@Injectable()
export class GeonetworkToolService {
  constructor(private _httpClient: HttpClient) { }

  loadGeonetworkTools() {
    return lastValueFrom(this._httpClient.get<GeoNetworkTool[]>(`api/tools`));
  }
}
