import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable()
export class SnackbarService {
  /**
   *
   */
  constructor(private _snackbar: MatSnackBar) {}


  public openSuccessMessage(message: string, action?: string) {
    this._snackbar.open(message, action, {
      panelClass: ['snackbar-success'],
      duration: 3000
    })
  }

  public openErrorMessage(message: string, action?: string) {
    this._snackbar.open(message, action, {
      panelClass: ['snackbar-error'],
      duration: 3000
    })
  }
}
