import { ExternalReferenceType } from "@models/dataset-metainfo";

export const DEFAULT_OPACITY = 0.75;
export const DEFAULT_COLORSCALE = "49611f06-88a9-4757-89c7-8db0e0f78115";

export const GEONETWORK_URL = "https://mood-geonetwork.avia-gis.com/geonetwork/srv/eng/catalog.search#/metadata/"

export interface Platform {
  title: string;
  code: ExternalReferenceType;
  prefix: string;
}

export const EXTERNAL_REFERENCE_GEONETWORK: Platform = {
  code: ExternalReferenceType.GEONETWORK,
  title: "GeoNetwork (MOOD)",
  prefix: "https://geonetwork.mood-h2020.eu/geonetwork/srv/eng/catalog.search#/metadata/"
}

export const EXTERNAL_REFERENCE_ZENODO: Platform =  {
  code: ExternalReferenceType.ZENODO,
  title: "Zenodo",
  prefix: "https://zenodo.org/record/"
}

export const EXTERNAL_REFERENCE_TYPE_PLATFORMS: Platform[] = [
  EXTERNAL_REFERENCE_ZENODO,
  EXTERNAL_REFERENCE_GEONETWORK
]

export const DEFAULT_VECTOR_BORDER_OPACITY = 1;
export const DEFAULT_VECTOR_BORDER_COLOR = '#3388ff';
export const DEFAULT_VECTOR_BORDER_WEIGHT = 2;
export const DEFAULT_VECTOR_FILL_OPACITY = 0.2;
export const DEFAULT_VECTOR_FILL_COLOR = '#3388ff';

export const TIMESERIES_EXPLANATION_TEXT = "Time series data consists of sequences of values or events recorded at consistent time intervals, capturing how these values change over time. This type of data is essential for analyzing trends, predicting future values, and identifying seasonal patterns across various fields like finance, weather forecasting, and healthcare. Time series analysis helps in making informed decisions by understanding temporal dynamics.";

export const FOURIER_EXPLANATION_TEXT = "Fourier processing involves applying the Fourier Transform to decompose images into frequency components, enabling the analysis and manipulation of spatial patterns. This process is crucial for filtering, image enhancement, and pattern detection in raster data by isolating specific frequency bands. Through inverse Fourier Transform, these modified frequency components can be reassembled back into spatial domain to produce the processed image.";
