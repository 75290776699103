import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { SYSTEM_ROLE, USER_ROLE, UserInfo } from '@models/user';
import { AuthService } from '../auth.service';
import { BehaviorSubject, tap } from 'rxjs';
import { SnackbarService } from '../../snackbar.service';

@Component({
  selector: 'mood-profile-dialog',
  templateUrl: './profile-dialog.component.html',
  styleUrls: ['./profile-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileDialogComponent implements OnInit {
  user_role = new FormControl();

  userProfileForm = new FormGroup({
    email: new FormControl<string | undefined>({ value: undefined, disabled: true }),
    user_role: new FormControl(),
    system_roles: new FormControl<string[] | undefined>({ value: undefined, disabled: true })
  });

  constructor(public dialogRef: MatDialogRef<ProfileDialogComponent>, private _authService: AuthService, private _snackBarService: SnackbarService) { }

  ngOnInit(): void {
    this.userProfileForm.patchValue(this._authService.loggedInUser!);
  }

  public get hasSystemRoles() {
    return this._authService.loggedInUser?.system_roles?.length;
  }

  public possibleUserRoles = Object.values(USER_ROLE);
  public possibleSystemRoles = Object.values(SYSTEM_ROLE);


  public isSaveInProgress = new BehaviorSubject(false);

  saveUpdatedProfile() {
    this.isSaveInProgress.next(true);
    this._authService.updateUser(this.userProfileForm.value as UserInfo)
      .pipe(tap(() => {
        this.isSaveInProgress.next(false);
        this.userProfileForm.markAsPristine();
      }))
      .subscribe(() => {
        this._snackBarService.openSuccessMessage("User profile updated");
        this.dialogRef.close();
      });
  }
}
