<h2 mat-dialog-title>My profile</h2>
<mat-dialog-content [formGroup]="userProfileForm" class="profile-form">
  <mat-form-field>
    <mat-label>Registered email address</mat-label>
    <input matInput type="email" formControlName="email" required>
  </mat-form-field>

  <mat-form-field>
    <mat-label>User expertise level</mat-label>
    <mat-select formControlName="user_role">
      <mat-option *ngFor="let role of possibleUserRoles" [value]="role">
        {{ role | titlecase }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <ng-container *ngIf="hasSystemRoles">
    <mat-form-field>
      <mat-label>System defined roles, these cannot be modified manually.</mat-label>
      <mat-select formControlName="system_roles" multiple>
        <mat-option *ngFor="let role of possibleSystemRoles" [value]="role">
          {{ role | titlecase }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button
    [disabled]="userProfileForm.pristine || userProfileForm.invalid || (isSaveInProgress | async)"
    (click)="saveUpdatedProfile()">
    <ng-container *ngIf="(isSaveInProgress | async)">
      <span class="loading-spinner"></span>
    </ng-container>
    Update</button>
  <div class="spacer"></div>
  <button mat-raised-button mat-dialog-close>Close</button>
</mat-dialog-actions>
