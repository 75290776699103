import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../auth.service';

import { AuthErrors } from '@models/helpers/errors'

@Component({
  selector: 'mood-register-dialog',
  templateUrl: './register-dialog.component.html',
  styleUrls: ['./register-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegisterDialogComponent implements OnInit {
  registerForm = new FormGroup({
    email: new FormControl("", [Validators.required, Validators.email]),
    password: new FormControl("", [Validators.required]),
    password_confirm: new FormControl("", [Validators.required])
  });


  // resetPasswordForm = this.formBuilder.group(
  //   {
  //     newPassword: this.newPassword,
  //     confirmPassword: this.confirmPassword,
  //   },
  //   {
  //     validator: this.ConfirmedValidator('newPassword', 'confirmPassword'),
  //   }
  // );


  ConfirmedValidator(): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const password = formGroup.get('password') as FormControl;
      const confirmPassword = formGroup.get('password_confirm') as FormControl;

      let passwordsMatching = password.value === confirmPassword.value;

      if (passwordsMatching) {
        password.setErrors(null);
        confirmPassword.setErrors(null);
      } else {
        password.setErrors({ notMatching: true });
        confirmPassword.setErrors({ notMatching: true });
      }
      return null;
    }
  }

  constructor(public dialogRef: MatDialogRef<any>, private _authService: AuthService) { }

  ngOnInit(): void {
    this.registerForm.addValidators(
      this.ConfirmedValidator()
    )
  }

  submitRegistration() {
    this._authService.registerUser(this.emailControl?.value!, this.passwordControl?.value!)
      .subscribe({
        next: user => {
          this.dialogRef.close();
          // TODO: Act on the registration
        },
        error: (error: HttpErrorResponse) => {
          if (error.error) {
            let errorObject = error.error as { message: string, code: AuthErrors };

            switch (errorObject.code) {
              case AuthErrors.UserAlreadyExists:
                this.emailControl?.setErrors({ error: errorObject.message });
                break;
            }

            this.registerForm.markAllAsTouched();
          }
        }
      });
  }

  public getErrorMessage(control: AbstractControl | null) {
    if (control!.getError("error")) {
      return control!.getError("error");
    } else if (control!.hasError("email")) {
      return "Please provide a valid email address";
    } else if (control!.hasError("notMatching")) {
      return "The passwords do not match";
    } else {
      return "This field is required";
    }
  }

  public get emailControl() {
    return this.registerForm.get('email');
  }

  public get passwordControl() {
    return this.registerForm.get('password');
  }

  public get confirmPasswordControl() {
    return this.registerForm.get('password_confirm');
  }
}
