import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';

import { JwtHelperService } from '@auth0/angular-jwt';
import { SYSTEM_ROLE, USER_ROLE, UserInfo } from '@models/user';

import { LoginResult } from '@models/login-info';

import { tap } from 'rxjs';
import { LOCAL_STORAGE_REFRESH_TOKEN, LOCAL_STORAGE_TOKEN } from './auth.const';

const AUTH_API = "/api/auth"

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private _http: HttpClient) { }

  public _loggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  login(email: string, password: string): Observable<any> {
    return this._http.post<LoginResult>(`${AUTH_API}/login`, {
      email,
      password
    }).pipe(
      tap(response => this._handleLoginResponse(response))
    );
  }

  refreshToken(token: string) {
    return this._http.post<LoginResult>(`${AUTH_API}/refreshtoken`, {
      refreshToken: token
    }).pipe(
      tap(response => this._handleLoginResponse(response))
    );
  }

  public get loggedInUser() {
    return this.getUser();
  }

  public get userHasAdminRole() {
    return this.getUser()?.system_roles?.includes(SYSTEM_ROLE.ADMIN);
  }

  public get userHasPartnerRole() {
    return this.getUser()?.system_roles?.includes(SYSTEM_ROLE.PARTNER);
  }

  public get userHasExpertRole() {
    return this.getUser()?.user_role?.includes(USER_ROLE.EXPERT);
  }

  public get userHasModellerRole() {
    return this.getUser()?.system_roles?.includes(SYSTEM_ROLE.MODELLER);
  }

  public get userIsExpert() {
    return this.userHasAdminRole || this.userHasModellerRole || this.userHasExpertRole;
  }

  private _handleLoginResponse(result: LoginResult) {
    this.saveToken(result.token);
    this.saveRefreshToken(result.refreshToken);

    this._handleTokenProcessing(result.token);
  }

  private _handleTokenProcessing(token: string): void {
    const _jwtService = new JwtHelperService();
    const _user = _jwtService.decodeToken<UserInfo>(token);

    this._cachedUser = _user;
    this._loggedIn$.next(true);
  }

  //TODO: Verify
  public registerUser(email: string, password: string) {
    return this._http.post<LoginResult>('/api/auth/register', { email, password })
      .pipe(
        tap(res => this._handleLoginResponse(res)),
        // shareReplay(),
      );
  }

  public updateUser(updatedInfo: UserInfo) {
    return this._http.put<LoginResult>(`/api/user/self`, updatedInfo)
    .pipe(
      tap(res => this._handleLoginResponse(res))
    );
  }

  public logout(): void {
    localStorage.removeItem(LOCAL_STORAGE_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);

    this._cachedUser = null;

    this._loggedIn$.next(false);
  }

  // Local storage methods
  private _cachedUser: UserInfo | null = null;

  public saveToken(token: string): void {
    localStorage.removeItem(LOCAL_STORAGE_TOKEN);
    localStorage.setItem(LOCAL_STORAGE_TOKEN, token);
  }

  public getToken(): string | null {
    return localStorage.getItem(LOCAL_STORAGE_TOKEN);
  }

  public saveRefreshToken(token: string): void {
    localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);
    localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN, token);
  }

  public getRefreshToken(): string | null {
    return localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN);
  }

  public getUser(): UserInfo | null {
    if (!this._cachedUser) {
      const token = localStorage.getItem(LOCAL_STORAGE_TOKEN);

      if(token) this._handleTokenProcessing(token);
    }

    return this._cachedUser;
  }
}
