export interface UserInfo {
  user_id: string;
  email: string;
  // name: string;

  user_role: USER_ROLE;
  system_roles: SYSTEM_ROLE[];
}

export enum USER_ROLE {
  BASIC = 'BASIC',
  MEDIUM = 'MEDIUM',
  EXPERT = 'EXPERT',
}

export enum SYSTEM_ROLE {
  ADMIN = 'ADMIN',
  DATA_PROVIDER = 'DATA PROVIDER',
  MODELLER = 'MODELLER',
  PARTNER = 'PARTNER',
}
