<h2 mat-dialog-title>Register for MOOD</h2>
<mat-dialog-content [formGroup]="registerForm" class="register-form">
  <mat-form-field>
    <input matInput type="email" placeholder="Email" formControlName="email" required>
    <mat-error>{{ getErrorMessage(emailControl) }}</mat-error>
  </mat-form-field>

  <mat-form-field>
    <input matInput type="password" placeholder="Password" formControlName="password" required>
    <mat-error>{{ getErrorMessage(passwordControl) }}</mat-error>
  </mat-form-field>

  <mat-form-field>
    <input matInput type="password" placeholder="Confirm Password" formControlName="password_confirm" required>
    <mat-error>{{ getErrorMessage(confirmPasswordControl) }}</mat-error>
  </mat-form-field>
  <!-- <mat-error *ngIf="passwordsNotMatching">Passwords do no match</mat-error> -->
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close>Cancel</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-raised-button color="primary" [disabled]="registerForm.invalid"
    (click)="submitRegistration()">Register</button>
</mat-dialog-actions>
