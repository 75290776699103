import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginDialogComponent } from './auth/login/login-dialog.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthInterceptor } from './auth/auth.interceptor';
import { MatButtonModule } from '@angular/material/button';
import { RegisterDialogComponent } from './auth/register/register-dialog.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ProfileDialogComponent } from './auth/profile/profile-dialog.component';
import { MatSelectModule } from '@angular/material/select';
import { SnackbarService } from './snackbar.service';
import { LayerAlreadyExistsDialogComponent } from '../dialogs/layer-already-exists-dialog/layer-already-exists-dialog.component';



@NgModule({ declarations: [
        LoginDialogComponent,
        RegisterDialogComponent,
        ProfileDialogComponent,
        LayerAlreadyExistsDialogComponent
    ],
    exports: [
        LoginDialogComponent,
        RegisterDialogComponent,
        LayerAlreadyExistsDialogComponent,
        CommonModule,
        BrowserModule
    ], imports: [BrowserModule,
        CommonModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatSelectModule,
        MatDialogModule], providers: [
        SnackbarService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class ApplicationCoreModule {
  constructor( @Optional() @SkipSelf() parentModule: ApplicationCoreModule) {
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core modules in the AppModule only.`);
   }
  }
}
