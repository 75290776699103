<h2 mat-dialog-title>Log in to MOOD</h2>
<mat-dialog-content [formGroup]="loginForm" class="login-form">
  <mat-form-field>
    <input matInput type="email" placeholder="Email" formControlName="email" required autocomplete="off">
    <mat-error>{{ getErrorMessage(emailControl) }}</mat-error>
  </mat-form-field>

  <mat-form-field>
    <input matInput type="password" placeholder="Password" formControlName="password" required>
    <mat-error>{{ getErrorMessage(passwordControl) }}</mat-error>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close>Cancel</button>
  <button mat-raised-button (click)="openRegister()">Register</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-raised-button color="primary" [disabled]="loginForm.invalid" (click)="submitLogin()">Login</button>
</mat-dialog-actions>
