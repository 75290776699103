<mat-toolbar>
  <div class="left">
  </div>

  <div class="middle">
    <img alt="MOOD" src="assets/images/logo_mood.png" />
  </div>

  <div class="right">
    <button mat-raised-button [matMenuTriggerFor]="toolsMenu" class="tools-button">
      <!-- <mat-icon svgIcon="widgets"></mat-icon> -->
      <span>Modules</span>
    </button>

    <button mat-raised-button [matMenuTriggerFor]="geonetworkCategoriesMenu" [matMenuTriggerData]="{ categories: toolEntries }" class="tools-button">
      <!-- <mat-icon svgIcon="forward"></mat-icon> -->
      <span>Tools</span>
    </button>

    <button mat-raised-button [matMenuTriggerFor]="geonetworkCategoriesMenu" [matMenuTriggerData]="{ categories: codeEntries }" class="tools-button">
      <!-- <mat-icon svgIcon="forward"></mat-icon> -->
      <span>Code</span>
    </button>
  </div>
</mat-toolbar>

<mat-menu #geonetworkToolsMenu="matMenu">
  <ng-template matMenuContent let-tools="tools">
    <ng-container *ngFor="let tool of tools; let last = last">
      <div mat-menu-item (click)="switchToGeoTool(tool)">
        <h4 class="tool-label">{{ tool.title }}</h4>
        <sub class="sub-text">{{ tool.abstract }}</sub>
      </div>
      <mat-divider class="menu-divider" *ngIf="!last"></mat-divider>
    </ng-container>
  </ng-template>
</mat-menu>

<mat-menu #toolsMenu="matMenu">
  <ng-container *ngFor="let tool of tools">
    <button mat-menu-item (click)="switchToTool(tool)">{{ tool.label }}</button>
  </ng-container>
</mat-menu>

<mat-menu #geonetworkCategoriesMenu="matMenu">
  <ng-template matMenuContent let-categories="categories">
    <ng-container *ngFor="let category of categories">
      <button mat-menu-item [matMenuTriggerFor]="geonetworkToolsMenu" [matMenuTriggerData]="{ tools: category.tools }">{{ category.label }}</button>
    </ng-container>
  </ng-template>
</mat-menu>

