import { Injectable } from '@angular/core';


import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class InterceptorErrorService {
  constructor(private _matSnackbar: MatSnackBar) {}

  public notifySessionExpired() {
    this._matSnackbar.open("Login session expired, please log in again.");
  }
}
