import { Component } from '@angular/core';

@Component({
  selector: 'mood-layer-exists-dialog',
  templateUrl: './layer-already-exists-dialog.component.html',
  styleUrls: ['./layer-already-exists-dialog.component.scss']
})
export class LayerAlreadyExistsDialogComponent {
  constructor() { }
}
